import {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {cmd} from 'websocket/websocket';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import SettingsIcon from '@material-ui/icons/Settings';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
	title: {
		flexGrow: 1,
	},
	colorPrimary: {
		color: 'white',
		backgroundColor: '#202020',
	},
}));

const HeaderSettings = ({open, onClose}) => {
	const tick_rate = useSelector(({app}) => app.settings.tick_rate);
	const [tickRate, setTickRate] = useState(10);

	useEffect(() => setTickRate(tick_rate), [tick_rate]);

	const save = () => {
		if (tickRate !== tick_rate)
			cmd('settings_update', {tick_rate: tickRate});
		onClose();
	}

	return (
		<Dialog
			open={open}
			onClose={onClose}
		>
			<DialogTitle>Global Settings</DialogTitle>
			<DialogContent>
				<FormControl>
					<InputLabel htmlFor='tick-rate'>Tick rate</InputLabel>
					<Input
						id='tick-rate'
						type='number'
						min='1'
						max='3600'
						value={tickRate}
						onChange={(event) => setTickRate(+event.target.value)}
						endAdornment={
							<InputAdornment position="end">
								seconds
							</InputAdornment>
						}
					/>
				</FormControl>
			</DialogContent>
			<DialogActions>
				<Button color='secondary' onClick={onClose}>Cancel</Button>
				<Button color='primary' onClick={save}>Save</Button>
			</DialogActions>
		</Dialog>
	);
}

const Header = () => {
	const classes = useStyles();
	const [settingsOpen, setSettingsOpen] = useState(false);

	return (
		<AppBar
			position='static'
			classes={{
				colorPrimary: classes.colorPrimary,
			}}
		>
			<Toolbar>
				<Typography variant="h6" className={classes.title}>
					Fermentation Regulators
				</Typography>
				{/*<IconButton
					onClick={() => setSettingsOpen(true)}
					color="inherit"
				>
					<SettingsIcon />
				</IconButton>*/}
			</Toolbar>
			{/*<HeaderSettings
				open={settingsOpen}
				onClose={() => setSettingsOpen(false)}
			/>*/}
		</AppBar>
	);
};

export default Header;
