import { createSlice } from '@reduxjs/toolkit'

function create_regulator(state, key)
{
	state.regulators[key] = {
		target:		null,
		temp:		null,
		data_active:	true,
		heater:		{},
		cooler:		{},
		data:		{},
	};

	state.regulator_list.push(key);
}

export const app_slice = createSlice({
	name: 'app',
	initialState: {
		settings: {
			tick_rate: 10 * 1000,
		},
		connected_state: 'connecting',
		timescale: 2,
		regulators: {},
		regulator_list: [],
	},
	reducers: {
		update_settings: (state, action) => {
			state.settings.tick_rate = action.payload.tick_rate;

			for (let [key, val] of Object.entries(action.payload.objects || {})) {
				if (val?.type === 0) {
					if (!state.regulators[key])
						create_regulator(state, key);
					Object.assign(state.regulators[key], val);
				}
			}
		},
		update_state: (state, action) => {
			let msg = action.payload;
			let ob = msg.data;
			let id = msg.id;

			if (ob?.type === 0) {
				if (!state.regulators[id])
					create_regulator(state, id);
				Object.assign(state.regulators[id], ob);
			}
		},
		update_history: (state, action) => {
			let msg = action.payload;
			let ob = state.regulators[msg.id];
			if (!ob)
				return;

			for (let d of msg.data)
				ob.data[d.time] = d;
		},
		update_state_latest: (state, action) => {
			let el = action.payload;

			if (el.id === undefined) {
				console.log('No id in state msg.');
				return;
			}

			if (!state.regulators[el.id])
				create_regulator(state, el.id);

			let reg = state.regulators[el.id];

			reg.data[el.data.time]	= el.data;
			reg.heater.on		= el.data.heater_on;
			reg.cooler.on		= el.data.cooler_on;
			reg.target		= el.data.target;
			reg.temp		= el.data.temp;
		},
		update_connected_state: (state, action) => {
			state.connected_state = action.payload;
		},
		set_data_active: (state, action) => {
			let {id, val} = action.payload || {};

			if (!id || !state.regulators[id])
				return;

			state.regulators[id].data_active = val;
		},
		set_timescale: (state, action) => {
			state.timescale = action.payload;
		},
	}
});

export const {
	update_settings, update_state, update_history, update_state_latest,
	update_connected_state, set_data_active, set_timescale,
} = app_slice.actions;

export default app_slice.reducer;
