import {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import _ from 'lodash';
import {cmd} from 'websocket/websocket';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';

import RegulatorInput from 'components/RegulatorInput';

const useStyles = makeStyles((theme) => ({
	divider: {
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
	},
}));

const RegulatorSettings= ({id, title, open, onClose}) => {
	const classes = useStyles();
	const settings = {
		id:	id,
		target: useSelector(({app}) => app.regulators[id]?.target),
		cooler: {
			threshold:	useSelector(({app}) => app.regulators[id]?.cooler?.threshold),
			watermark:	useSelector(({app}) => app.regulators[id]?.cooler?.watermark),
			min_on:		useSelector(({app}) => app.regulators[id]?.cooler?.min_on),
			min_off:	useSelector(({app}) => app.regulators[id]?.cooler?.min_off),
		},
		heater: {
			threshold:	useSelector(({app}) => app.regulators[id]?.heater?.threshold),
			watermark:	useSelector(({app}) => app.regulators[id]?.heater?.watermark),
			min_on:		useSelector(({app}) => app.regulators[id]?.heater?.min_on),
			min_off:	useSelector(({app}) => app.regulators[id]?.heater?.min_off),
		},
	};
	const [target, setTarget] = useState();
	useEffect(() => setTarget(settings.target), [settings.target]);
	const [coolerThresh, setCoolerThresh] = useState();
	useEffect(() => setCoolerThresh(settings.cooler.threshold), [settings.cooler.threshold]);
	const [coolerWatermark, setCoolerWatermark] = useState();
	useEffect(() => setCoolerWatermark(settings.cooler.watermark), [settings.cooler.watermark]);
	const [coolerMinOn, setCoolerMinOn] = useState();
	useEffect(() => setCoolerMinOn(settings.cooler.min_on), [settings.cooler.min_on]);
	const [coolerMinOff, setCoolerMinOff] = useState();
	useEffect(() => setCoolerMinOff(settings.cooler.min_off), [settings.cooler.min_off]);
	const [heaterThresh, setHeaterThresh] = useState();
	useEffect(() => setHeaterThresh(settings.heater.threshold), [settings.heater.threshold]);
	const [heaterWatermark, setHeaterWatermark] = useState();
	useEffect(() => setHeaterWatermark(settings.heater.watermark), [settings.heater.watermark]);
	const [heaterMinOn, setHeaterMinOn] = useState();
	useEffect(() => setHeaterMinOn(settings.heater.min_on), [settings.heater.min_on]);
	const [heaterMinOff, setHeaterMinOff] = useState();
	useEffect(() => setHeaterMinOff(settings.heater.min_off), [settings.heater.min_off]);

	const toggleTargetEnabled = () => {
		setTarget(target === null ? 0 : null);
	};

	const save = () => {
		let s = {
			id:	id,
			target:	target === null ? target : +target,
			cooler: {
				threshold:	+coolerThresh,
				watermark:	+coolerWatermark,
				min_on:		+coolerMinOn,
				min_off:	+coolerMinOff,
			},
			heater: {
				threshold:	+heaterThresh,
				watermark:	+heaterWatermark,
				min_on:		+heaterMinOn,
				min_off:	+heaterMinOff,
			},
		};
		if (!_.isEqual(settings, s))
			cmd('settings_update', s);
		onClose();
	}

	return (
		<Dialog
			open={open}
			onClose={onClose}
			maxWidth='sm'
		>
			<DialogTitle>{title} Settings</DialogTitle>
			<DialogContent>
				<Grid
					container
					spacing={2}
				>
					<Grid item xs={12}>
						<Typography variant='subtitle2'>General</Typography>
					</Grid>

					<Grid item xs={10} md={6}>
						<RegulatorInput
							id={id}
							name='target'
							label='Target'
							type='number'
							min='-10'
							max='50'
							step='0.1'
							suffix='°C'
							value={target === null ? '' : target}
							disabled={target === null}
							onChange={setTarget}
						/>
					</Grid>

					<Grid item xs={2}>
						<Checkbox
							checked={target !== null}
							onChange={toggleTargetEnabled}
						/>
					</Grid>

				</Grid>

				<div className={classes.divider} />

				<Grid
					container
					spacing={2}
					justify="center"
				>
					<Grid item xs={12}>
						<Typography variant='subtitle2'>Cooler</Typography>
					</Grid>

					<Grid item xs={12} md={6}>
						<RegulatorInput
							id={id}
							name='thresh'
							label='Start threshold'
							type='number'
							min='0'
							max='10'
							step='0.1'
							suffix='°C'
							value={coolerThresh}
							onChange={setCoolerThresh}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<RegulatorInput
							id={id}
							name='watermark'
							label='Stop watermark'
							type='number'
							min='0'
							max='10'
							step='0.1'
							suffix='°C'
							value={coolerWatermark}
							onChange={setCoolerWatermark}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						<RegulatorInput
							id={id}
							name='min_off'
							label='Minimum off time'
							type='number'
							min='0'
							max='7200'
							step='1'
							suffix='seconds'
							value={coolerMinOff}
							onChange={setCoolerMinOff}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						<RegulatorInput
							id={id}
							name='min_on'
							label='Minimum on time'
							type='number'
							min='0'
							max='7200'
							step='1'
							suffix='seconds'
							value={coolerMinOn}
							onChange={setCoolerMinOn}
						/>
					</Grid>
				</Grid>

				<div className={classes.divider} />

				<Grid
					container
					spacing={2}
					justify="center"
				>
					<Grid item xs={12}>
						<Typography variant='subtitle2'>Heater</Typography>
					</Grid>

					<Grid item xs={12} md={6}>
						<RegulatorInput
							id={id}
							name='thresh'
							label='Start threshold'
							type='number'
							min='0'
							max='10'
							step='0.1'
							suffix='°C'
							value={heaterThresh}
							onChange={setHeaterThresh}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<RegulatorInput
							id={id}
							name='watermark'
							label='Stop watermark'
							type='number'
							min='0'
							max='10'
							step='0.1'
							suffix='°C'
							value={heaterWatermark}
							onChange={setHeaterWatermark}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						<RegulatorInput
							id={id}
							name='min_off'
							label='Minimum off time'
							type='number'
							min='0'
							max='7200'
							step='1'
							suffix='seconds'
							value={heaterMinOff}
							onChange={setHeaterMinOff}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						<RegulatorInput
							id={id}
							name='min_on'
							label='Minimum on time'
							type='number'
							min='0'
							max='7200'
							step='1'
							suffix='seconds'
							value={heaterMinOn}
							onChange={setHeaterMinOn}
						/>
					</Grid>
				</Grid>

				<div className={classes.divider} />
			</DialogContent>

			<DialogActions>
				<Button color='secondary' onClick={onClose}>Cancel</Button>
				<Button color='primary' onClick={save}>Save</Button>
			</DialogActions>
		</Dialog>
	);
}

export default RegulatorSettings;
