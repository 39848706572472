import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
	root: {
		minWidth: '50px',
		marginRight: theme.spacing(1),
	},
	tempNormal: {
	},
	tempUnknown: {
		color: 'grey',
	},
	tempUnit: {
		paddingLeft: theme.spacing(1),
		color: 'grey',
	},
}));

const PrettyTemp = ({children, ...props}) => {
	const classes = useStyles();
	const t = children;
	const unset = typeof t !== 'number';

	return (
	<div className={classes.root}>
		<Typography variant='h6' noWrap {...props}>
			{!unset && <span>{t.toFixed(1)}</span>}
			{ unset && <span className={classes.tempUnknown}>---</span>}
			<Typography component={'span'}>
				<span className={classes.tempUnit}>°C</span>
			</Typography>
		</Typography>
	</div>
	);
};

export default PrettyTemp;
