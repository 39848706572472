import SvgIcon from '@material-ui/core/SvgIcon';

const outside = "M62.329,63.011V12.329C62.329,5.53,56.799,0,50,0c-6.798,0-12.329,5.53-12.329,12.329v50.682  c-5.14,3.84-8.219,9.875-8.219,16.441C29.452,90.783,38.67,100,50,100s20.548-9.217,20.548-20.548  C70.548,72.886,67.469,66.851,62.329,63.011z M50,94.521c-8.322,0-15.068-6.747-15.068-15.068c0-5.854,3.341-10.925,8.219-13.421  V12.329c0-3.783,3.066-6.849,6.849-6.849s6.849,3.066,6.849,6.849v53.703c4.877,2.495,8.219,7.566,8.219,13.421  C65.068,87.774,58.322,94.521,50,94.521z"
const inside = "M53.893,68.925V36.174h-7.786v32.751c-4.283,1.584-7.342,5.693-7.342,10.527c0,6.206,5.03,11.234,11.235,11.234  s11.234-5.028,11.234-11.234C61.234,74.618,58.176,70.509,53.893,68.925z";

function ThermometerIcon({active, forced, ...props})
{
	return (
		<SvgIcon {...props}>
			<svg viewBox="0 0 100 100">
				<path d={outside} />
				<path d={inside} />
			</svg>
		</SvgIcon>
	);
}

export default ThermometerIcon;
