import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';

const RegulatorInput = ({id, name, label, onChange, suffix, ...props}) => (
	<FormControl fullWidth>
		<InputLabel htmlFor={id + '-' + name}>{label}</InputLabel>
		<Input
			margin='dense'
			variant='outlined'
			id={id + '-' + name}
			onChange={(event) => onChange(event.target.value)}
			endAdornment={
				suffix &&
				<InputAdornment position="end">{suffix}</InputAdornment>
			}
			{...props}
		/>
	</FormControl>
);

export default RegulatorInput;
