import SvgIcon from '@material-ui/core/SvgIcon';
import 'components/CoolerIcon.css';

const flakes = "M250.352,69.807v32.149l48.22-26.784v25.717l-48.22,28.924v28.924l48.22-27.843v56.778l25.71-14.997  v-56.8l23.577-12.849v56.793l32.142-19.3l11.787,20.374l-33.216,19.27l49.279,27.858l-21.429,12.864l-51.427-28.924l-25.71,16.063  l50.353,27.865l-50.353,28.924l25.71,14.997l52.509-26.784l20.348,11.787l-49.279,28.924l33.216,18.211l-10.713,19.293  l-33.216-19.293v55.726l-23.577-11.787v-55.723l-25.71-16.056v55.704l-48.22-26.776v30.002l48.22,27.85v24.651l-48.22-28.931v37.496  h-24.643v-37.496l-49.279,28.931V370.89l49.279-27.85v-30.002l-50.361,26.776V284.11l-25.71,16.056v55.726l-21.429,11.787v-55.726  l-34.29,19.293l-10.705-19.293l33.208-18.211l-48.22-28.924l19.285-11.787l52.501,26.784l25.71-14.997l-50.353-28.924l50.353-27.865  l-25.71-16.063l-52.501,28.924L66.05,208.025l49.294-27.858l-31.068-19.27l10.713-20.374l33.216,19.3V103.03l21.429,12.849v56.8  l25.71,14.997v-56.782l50.361,27.843v-28.924l-49.279-28.924V75.173l49.279,26.784V69.807  C225.705,69.807,250.352,69.807,250.352,69.807z M162.502,235.894l26.776,14.993l26.791-14.993l-26.791-13.931L162.502,235.894z   M197.858,174.82v28.924l27.85,15.005v-32.149L197.858,174.82z M197.858,298.033l27.85-11.787v-32.142l-27.85,13.931  L197.858,298.033L197.858,298.033z M250.352,186.599v32.149l26.791-17.152V174.82L250.352,186.599z M250.352,286.243l26.791,11.787  v-26.784l-26.791-17.141V286.243z M263.216,235.894l25.71,14.993l26.791-14.993l-26.791-13.931L263.216,235.894z";

const CoolerIcon = ({active, ...props}) => (
	<SvgIcon {...props}>
		<svg viewBox="0 0 473.935 473.935" className={active ? "active" : ""}>
			<circle cx="236.967" cy="236.967" r="236.967" className="cooler-circle" />
			<path d={flakes} className="flakes" />
		</svg>
	</SvgIcon>
)

export default CoolerIcon;
