import SvgIcon from '@material-ui/core/SvgIcon';
import 'components/HeaterIcon.css';

const flame_outer = "M186.141,168.802c18.63-48.445,15.042-121.667,15.042-121.667s49.541,20.759,83.744,77.761 c32.149,53.578,1.777,133.398,1.777,133.398c23.685-23.693,32.464-47.382,33.268-75.827c26.608,42.473,47.165,91.146,47.165,140.398 c0,60.938-55.412,110.285-123.837,110.318c-68.396-0.037-123.837-49.38-123.837-110.318 C119.463,263.555,164.173,225.931,186.141,168.802z";
const flame_middle = "M210.455,272.583c10.222-29.384,8.258-73.791,8.258-73.791s98.12,74.674,91.131,167.227 c-2.791,36.856-30.424,66.888-68.01,66.91c-37.556-0.022-68.003-29.949-68.003-66.91 C173.835,330.049,198.388,307.232,210.455,272.583z";
const flame_inner = "M222.017,330.072c6.481-18.619,5.231-46.753,5.231-46.753s62.169,47.318,57.743,105.963 c-1.77,23.349-19.278,42.372-43.09,42.39c-23.794-0.019-43.09-18.978-43.09-42.39C198.815,366.483,214.38,352.025,222.017,330.072z";

const HeaterIcon = ({active, ...props}) => (
	<SvgIcon {...props}>
		<svg viewBox="0 0 473.931 473.931" className={active ? "active" : ""}>
			<circle cx="236.966" cy="236.966" r="236.966" className="heater-circle" />
			<path d={flame_outer} className="flame-outer" />
			<path d={flame_middle} className="flame-middle" />
			<path d={flame_inner} className="flame-inner" />
		</svg>
	</SvgIcon>
)

export default HeaterIcon;
