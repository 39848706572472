import store from 'data/store';
import {
	update_settings, update_state, update_history, update_state_latest,
	update_connected_state, set_data_active,
} from 'data/app';

/* --- variables --- */
const HOST = window.location.hostname;

let ws_state = {
	ws:		null,
	is_connected:	false,
	is_connecting:	false,
	timeouts: {},
};

/* --- functions --- */
function cmd(type, data)
{
	let msg = JSON.stringify({cmd: type, data: data});
	console.log('WSTX:', msg);
	ws_state.ws.send(msg);
}

function connect()
{
	if (ws_state.is_connecting || ws_state.is_connected)
		return;

	ws_state.is_connecting = true;
	store.dispatch(update_connected_state('connecting'));

	let path = 'wss://' + HOST;
	ws_state.ws		= new WebSocket(path, 'fridge');
	ws_state.ws.onopen	= connected_cb;
	ws_state.ws.onclose	= disconnected_cb;
	ws_state.ws.onmessage	= message_cb;
}

function connected_cb()
{
	let was_reconnecting = ws_state.reconnecting;

	ws_state.is_connected	= true
	ws_state.is_connecting	= false;
	store.dispatch(update_connected_state('connected'));

	if (was_reconnecting) {
		clearInterval(ws_state.reconnecting);
		delete ws_state.reconnecting;
	}

	/* query all the latest app data */
	cmd('settings');
	cmd('query');
}

function disconnected_cb()
{
	if (!ws_state.is_connected && !ws_state.is_connecting)
		return;

	ws_state.ws		= null;
	if (!ws_state.reconnecting)
		ws_state.reconnecting = setInterval(connect, 5000);

	ws_state.is_connected	= false;
	ws_state.is_connecting	= false;
	store.dispatch(update_connected_state('disconnected'));
}

function data_timeout_cb(id)
{
	store.dispatch(set_data_active({id, val: false}));
}

function wait_for_next_data(id, tick_rate)
{
	store.dispatch(set_data_active({id, val: true}));

	let timeout = (store.getState().app.settings.tick_rate + 2) * 1000;
	if (ws_state.timeouts[id])
		clearTimeout(ws_state.timeouts[id]);
	ws_state.timeouts[id] = setTimeout(() => data_timeout_cb(id),
					   timeout);
}

function message_cb(msg)
{
	msg = JSON.parse(msg.data);
	msg.id = String(msg.id);
	console.log('WSRX:', msg);

	if (msg.type === 'settings') {
		store.dispatch(update_settings(msg.data));
		for (let id of Object.keys(msg.data?.regulators || {}))
			wait_for_next_data(id);
	} else if (msg.type === 'state') {
		store.dispatch(update_state(msg));
	} else if (msg.type === 'history') {
		store.dispatch(update_history(msg));
	} else if (msg.type === 'status') {
		store.dispatch(update_state_latest(msg));
		wait_for_next_data(msg.data?.id);
	} else {
		console.log('No handler for message type.', msg);
	}
}

export {connect, cmd};
