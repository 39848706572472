import {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import _ from 'lodash';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ToggleButton from '@material-ui/lab/ToggleButton';

import {cmd} from 'websocket/websocket';
import CoolerIcon from 'components/CoolerIcon';
import HeaterIcon from 'components/HeaterIcon';

const useStyles = makeStyles((theme) => ({
	override: {
		display: 'block',
	},
	toggleContainer: {
		display: 'flex',
		justifyContent: 'center',
	},
	toggle: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	}
}));

const RegulatorOverrideDialog = ({id, title, open, onClose}) => {
	const classes = useStyles();
	const settings = {
		cooler: {override: useSelector(({app}) => app.regulators[id]?.cooler?.override)},
		heater: {override: useSelector(({app}) => app.regulators[id]?.heater?.override)},
	};
	const [cooler, setCooler] = useState()
	useEffect(() => setCooler(settings.cooler.override), [open, settings.cooler.override])
	const [heater, setHeater] = useState()
	useEffect(() => setHeater(settings.heater.override), [open, settings.heater.override])
	const [override, setOverride] = useState();
	useEffect(() => setOverride(![null, undefined].includes(settings.cooler.override) ||
				    ![null, undefined].includes(settings.heater.override)),
		  [open, settings.cooler.override, settings.heater.override]);

	const handleOverrideClick = () => {
		setCooler(null);
		setHeater(null);
		setOverride(!override);
	};

	const save = () => {
		let s = {
			id:	id,
			cooler:	{override: cooler},
			heater:	{override: heater},
		};
		if (!_.isEqual(settings, s))
			cmd('settings_update', s);
		onClose();
	}

	return (
		<Dialog
			open={open}
			onClose={onClose}
		>
			<DialogTitle>{title} Overrides</DialogTitle>
			<DialogContent>
				<FormControlLabel
					className={classes.override}
					label='Enable override'
					control={
						<Checkbox
							checked={override}
							onChange={handleOverrideClick}
							name='override'
						/>
					}
				/>

				{override &&
				<div className={classes.toggleContainer}>
					<ToggleButton
						className={classes.toggle}
						value='cooler'
						size='large'
						selected={cooler}
						disabled={!override}
						onChange={() => setCooler(!cooler)}
					>
						<CoolerIcon active={cooler} />
					</ToggleButton>
					<ToggleButton
						className={classes.toggle}
						value='heater'
						size='large'
						selected={heater}
						disabled={!override}
						onChange={() => setHeater(!heater)}
					>
						<HeaterIcon active={heater} />
					</ToggleButton>
				</div>
				}
			</DialogContent>

			<DialogActions>
				<Button color='secondary' onClick={onClose}>Cancel</Button>
				<Button color='primary' onClick={save}>Save</Button>
			</DialogActions>
		</Dialog>
	);
}

export default RegulatorOverrideDialog;
