import {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import _ from 'lodash';
import {cmd} from 'websocket/websocket';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

import RegulatorInput from 'components/RegulatorInput';

const useStyles = makeStyles((theme) => ({
	divider: {
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
	},
}));

const RegulatorTargetDialog = ({id, title, open, onClose}) => {
	const classes = useStyles();
	const settings = {
		target: useSelector(({app}) => app.regulators[id]?.target),
	};
	const [target, setTarget] = useState();
	useEffect(() => setTarget(settings.target), [settings.target]);

	const toggleTargetEnabled = () => {
		setTarget(target === null ? 0 : null);
	};

	const save = () => {
		let s = {
			id:	id,
			target:	target === null ? target : +target,
		};
		if (!_.isEqual(settings, s))
			cmd('settings_update', s);
		onClose();
	}

	return (
		<Dialog
			open={open}
			onClose={onClose}
		>
			<DialogTitle>{title} Target</DialogTitle>
			<DialogContent>
				<Grid
					container
					spacing={2}
				>
					<Grid item xs={10}>
						<RegulatorInput
							id={id}
							name='target'
							label='Target'
							type='number'
							min='-10'
							max='50'
							step='0.1'
							suffix='°C'
							value={target === null ? '' : target}
							disabled={target === null}
							onChange={setTarget}
						/>
					</Grid>

					<Grid item xs={2}>
						<Checkbox
							checked={target !== null}
							onChange={toggleTargetEnabled}
						/>
					</Grid>

				</Grid>
				<div className={classes.divider} />
			</DialogContent>

			<DialogActions>
				<Button color='secondary' onClick={onClose}>Cancel</Button>
				<Button color='primary' onClick={save}>Save</Button>
			</DialogActions>
		</Dialog>
	);
}

export default RegulatorTargetDialog;
