import {useSelector} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Backdrop from '@material-ui/core/Backdrop';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';

import Header from 'components/Header';
import Regulator from 'components/Regulator';
import 'css/normalize.min.css';

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
	},
}));

const App = () => {
	const classes = useStyles();
	const list = useSelector(({app}) => app.regulator_list);
	const state = useSelector(({app}) => app.connected_state);

	return (
	<>
		<Header />
		<Backdrop
			className={classes.backdrop}
			open={state !== 'connected'}
		>
			{state === 'connecting' &&
				<CircularProgress color='inherit' />
			}
			{state === 'disconnected' &&
				<Alert severity="error">
					Disconnected
				</Alert>
			}
		</Backdrop>
		<Container maxWidth="lg">
			{list.map((key) => (
				<Regulator id={key} key={key} />
			))}
		</Container>
	</>
	);
}

export default App;
