import {useState} from 'react';
import {useSelector} from 'react-redux';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Backdrop from '@material-ui/core/Backdrop';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import EditIcon from '@material-ui/icons/Edit';
import FormControl from '@material-ui/core/FormControl';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';

import store from 'data/store';
import { set_timescale } from 'data/app';
import CoolerIcon from 'components/CoolerIcon';
import HeaterIcon from 'components/HeaterIcon';
import PrettyTemp from 'components/PrettyTemp';
import RegulatorGraph from 'components/RegulatorGraph';
import RegulatorOverrideDialog from 'components/RegulatorOverrideDialog';
import RegulatorSettings from 'components/RegulatorSettings';
import RegulatorTargetDialog from 'components/RegulatorTargetDialog';
import ThermometerIcon from 'components/ThermometerIcon';

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
		position: 'relative',
	},
	expand: {
		flexGrow: 1,
	},
	backdrop: {
		position: 'absolute',
		zIndex: 1,
	},
	graph: {
		marginTop: theme.spacing(-1),
	},
	override: {
		boxShadow: `0px 0px 3px 2px ${theme.palette.secondary.main}`,
		borderRadius: '24px',
		transition: 'box-shadow 500ms',
	},
	thermometerIcon: {
		marginLeft: theme.spacing(2),
	},
	targetIcon: {
		marginRight: -theme.spacing(1),
	},
}));

const TimescaleInput = withStyles((theme) => ({
	root: {
		'label + &': {
			marginTop: theme.spacing(3),
		},
		marginRight: theme.spacing(1),
	},
	input: {
		borderRadius: 4,
		position: 'relative',
		backgroundColor: theme.palette.background.paper,
		border: '1px solid #ced4da',
		fontSize: 14,
		padding: '10px 26px 10px 12px',
		transition: theme.transitions.create(['border-color', 'box-shadow']),
		'&:focus': {
			borderRadius: 4,
		},
	},
}))(InputBase);

const Regulator = ({id}) => {
	const classes = useStyles();
	const name = useSelector(({app}) => app.regulators[id]?.name);
	const target = useSelector(({app}) => app.regulators[id]?.target);
	const temp = useSelector(({app}) => app.regulators[id]?.temp);
	const active = useSelector(({app}) => app.regulators[id]?.data_active);
	const cooler = {
		on:		useSelector(({app}) => app.regulators[id]?.cooler?.on),
		override:	useSelector(({app}) => app.regulators[id]?.cooler?.override),
	};
	const heater = {
		on:		useSelector(({app}) => app.regulators[id]?.heater?.on),
		override:	useSelector(({app}) => app.regulators[id]?.heater?.override),
	};
	const [settingsOpen, setSettingsOpen] = useState(false);
	const [targetOpen, setTargetOpen] = useState(false);
	const [overrideOpen, setOverrideOpen] = useState(false);
	const timescale = useSelector(({app}) => app.timescale);
	const title = name;

	return (
		<Card className={classes.root}>
			<Backdrop
				classes={{root: classes.backdrop}}
				open={!Boolean(active)}
			>
				<Alert severity="error">
					No recent data from sensor.
				</Alert>
			</Backdrop>
			<CardHeader
				disableTypography
				title={
					<Typography
						component='span'
						variant='h6'
					>
						{title}
					</Typography>
				}
				avatar={
					<>
						<IconButton onClick={() => setOverrideOpen(true)}>
							<CoolerIcon
								active={cooler.on}
								className={cooler.override !== null && cooler.override !== undefined ? classes.override : null}
							/>
						</IconButton>
						<IconButton onClick={() => setOverrideOpen(true)}>
							<HeaterIcon
								active={heater.on}
								className={heater.override !== null && heater.override !== undefined ? classes.override : null}
							/>
						</IconButton>
					</>
				}
				action={
					<IconButton onClick={() => setSettingsOpen(true)}>
						<EditIcon />
					</IconButton>
				}
			/>
			<CardMedia classes={{root: classes.graph}} title="chart">
				<RegulatorGraph id={id} timescale={timescale} />
			</CardMedia>
			<CardActions>
				<Icon color='action' className={classes.thermometerIcon}>
					<ThermometerIcon />
				</Icon>
				<PrettyTemp>{temp}</PrettyTemp>
				<IconButton
					className={classes.targetIcon}
					onClick={() => setTargetOpen(true)}
				>
					<GpsFixedIcon />
				</IconButton>
				<PrettyTemp>{target}</PrettyTemp>
				<div className={classes.expand} />
				<FormControl>
					<Select
						value={timescale}
						onChange={(e) => store.dispatch(set_timescale(e.target.value))}
						input={<TimescaleInput />}
					>
						<MenuItem value={1}>1H</MenuItem>
						<MenuItem value={2}>2H</MenuItem>
						<MenuItem value={24}>1D</MenuItem>
						<MenuItem value={48}>2D</MenuItem>
						<MenuItem value={7 * 24}>1W</MenuItem>
						<MenuItem value={31 * 24}>1M</MenuItem>
						<MenuItem value={92 * 24}>3M</MenuItem>
						<MenuItem value={183 * 24}>6M</MenuItem>
						<MenuItem value={365 * 24}>1Y</MenuItem>
					</Select>
				</FormControl>
			</CardActions>

			<RegulatorSettings
				id={id}
				title={title}
				open={settingsOpen}
				onClose={() => setSettingsOpen(false)}
			/>
			<RegulatorTargetDialog
				id={id}
				title={title}
				open={targetOpen}
				onClose={() => setTargetOpen(false)}
			/>

			<RegulatorOverrideDialog
				id={id}
				title={title}
				open={overrideOpen}
				onClose={() => setOverrideOpen(false)}
			/>
		</Card>
	);
}

export default Regulator;
