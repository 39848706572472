import React, {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import * as d3 from 'd3';
import ReactFauxDOM from 'react-faux-dom';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	root: {
		minHeight: '300px',
	},
	grid: {
		fill: 'none',
		color: '#dddddd',
		opacity: '0.9',
		shapeRendering: 'crispEdges',
	},
	axis: {
		shapeRendering: 'crispEdges',
	},
	temp_graph: {
		fill: 'none',
		stroke: '#343a40',
		strokeWidth: '1.5',
		shapeRendering: 'auto',
	},
	target_graph: {
		fill: 'none',
		stroke: '#9c27b0',
		strokeWidth: '1.5',
		shapeRendering: 'crispEdges',
	},
	cooler_line: {
		stroke: '#03a9f4',
		fill: 'none',
		opacity: '0.5',
		strokeWidth: '2'
	},
	con_line: {
		stroke: '#03a9f4',
		fill: 'none',
		strokeWidth: '3',
		shapeRendering: 'crispEdges',
	},
	cooler_area: {
		fill: '#03a9f4',
		stroke: 'none',
		opacity: '0.5',
	},
	heater_line: {
		stroke: '#FF5722',
		fill: 'none',
		opacity: '0.5',
		strokeWidth: '2'
	},
	hon_line: {
		stroke: '#FF5722',
		fill: 'none',
		strokeWidth: '3',
		shapeRendering: 'crispEdges',
	},
	heater_area: {
		fill: '#FF9800',
		stroke: 'none',
		opacity: '0.5',
	},
	chtrans: {
		fill: 'none',
		stroke: '#000000',
		strokeWidth: '0.5',
		shapeRendering: 'crispEdges',
		strokeDasharray: '4, 4',
		opacity: '0.3',
	},
}));

const margins = {left: 45, right: 16, top: 8, bottom: 20 };
const min_temp_height = 3.0;

function render({id, data, svg, width, height, timescale, classes})
{
	if (!height || !width)
		return;

	const clip = svg.append("defs")
		.append("clipPath")
			.attr("id", `${id}-graph-clip`)
		.append("rect")
			.attr("x", 0)
			.attr("y", 0)
	const x_scale = d3.scaleTime()
	const y_scale = d3.scaleLinear()
	const x_grid = svg.append("g")
		.attr("transform", `translate(${margins.left},${margins.top})`)
		.attr("clip-path", `url(#${id}-graph-clip)`)
		.attr("class", classes.grid)
	const y_grid = svg.append("g")
		.attr("transform", `translate(${margins.left},${margins.top})`)
		.attr("clip-path", `url(#${id}-graph-clip)`)
		.attr("class", classes.grid)
	const x_axis = svg.append("g")
		.attr("class", classes.axis)
	const y_axis = svg.append("g")
		.attr("transform", `translate(${margins.left},${margins.top})`)
		.attr("class", classes.axis)
	const ytrans_scale = d3.scaleLinear()
	const yon_scale = d3.scaleLinear()
	const curvetype = d3.curveMonotoneX;
	const isNumber = (d) => d !== null && d !== undefined && !isNaN(d);
	const line = d3.line()
		.defined(d => isNumber(d.temp))
		.x((d) => x_scale(d.time * 1000))
		.y((d) => y_scale(d.temp))
		.curve(curvetype)
	const tline = d3.line()
		.defined((d) => isNumber(d.target))
		.x((d) => x_scale(d.time * 1000))
		.y((d) => y_scale(d.target))
		.curve(d3.curveStepBefore)
	const cline = d3.line()
		.defined((d) => d.cooler_on && isNumber(d.temp))
		.x((d) => x_scale(d.time * 1000))
		.y((d) => y_scale(d.temp))
		.curve(curvetype)
	const con = d3.line()
		.defined((d) => d.cooler_on)
		.x((d) => x_scale(d.time * 1000))
		.y((d) => yon_scale(d.cooler_on))
	const hon = d3.line()
		.defined((d) => d.heater_on)
		.x((d) => x_scale(d.time * 1000))
		.y((d) => yon_scale(d.heater_on))
	const hline = d3.line()
		.defined((d) => d.heater_on && isNumber(d.temp))
		.x((d) => x_scale(d.time * 1000))
		.y((d) => y_scale(d.temp))
		.curve(curvetype)
	const carea = d3.area()
		.defined((d) => d.cooler_on && isNumber(d.temp))
		.x((d) => x_scale(d.time * 1000))
		.y0((d) => !isNumber(d.target) ? 0 : y_scale(d.target))
		.y1((d) => !isNumber(d.target) ? height : y_scale(d.temp))
		.curve(curvetype)
	const harea = d3.area()
		.defined((d) => d.heater_on)
		.x((d) => x_scale(d.time * 1000))
		.y0((d) => !isNumber(d.target) ? 0 : y_scale(d.target))
		.y1((d) => !isNumber(d.target) ? height : y_scale(d.temp))
		.curve(curvetype)
	const chtransarea = d3.area()
		.defined((d) => d.heater_on || d.cooler_on)
		.x((d) => x_scale(d.time * 1000))
		.y0((d) => height)
		.y1((d) => ytrans_scale((d.heater_on || d.cooler_on) ? 1 : 0))
	const cooler_area = svg.append("path")
		.attr("transform", `translate(${margins.left},${margins.top})`)
		.attr("clip-path", `url(#${id}-graph-clip)`)
		.attr("class", classes.cooler_area)
	const heater_area = svg.append("path")
		.attr("transform", `translate(${margins.left},${margins.top})`)
		.attr("clip-path", `url(#${id}-graph-clip)`)
		.attr("class", classes.heater_area)
	const chtrans = svg.append("path")
		.attr("transform", `translate(${margins.left},${margins.top})`)
		.attr("clip-path", `url(#${id}-graph-clip)`)
		.attr("class", classes.chtrans)
	const target_chart = svg.append("path")
		.attr("transform", `translate(${margins.left},${margins.top})`)
		.attr("clip-path", `url(#${id}-graph-clip)`)
		.attr("class", classes.target_graph)
	const line_chart = svg.append("path")
		.attr("transform", `translate(${margins.left},${margins.top})`)
		.attr("clip-path", `url(#${id}-graph-clip)`)
		.attr("class", classes.temp_graph)
	const cooler_chart = svg.append("path")
		.attr("transform", `translate(${margins.left},${margins.top})`)
		.attr("clip-path", `url(#${id}-graph-clip)`)
		.attr("class", classes.cooler_line)
	const heater_chart = svg.append("path")
		.attr("transform", `translate(${margins.left},${margins.top})`)
		.attr("clip-path", `url(#${id}-graph-clip)`)
		.attr("class", classes.heater_line)
	const con_chart = svg.append("path")
		.attr("transform", `translate(${margins.left},${margins.top})`)
		.attr("class", classes.con_line)
	const hon_chart = svg.append("path")
		.attr("transform", `translate(${margins.left},${margins.top})`)
		.attr("class", classes.hon_line)

	/* update sizes and scales */
	width = width - margins.left - margins.right;
	height = height - margins.top - margins.bottom;

	clip.attr("width", width)
	    .attr("height", height)

	let data_tmp = Object.values(data).sort((a, b) => a.time - b.time);
	let time = Math.round(new Date() / 1000);
	let start = time - (timescale * 3600);
	data_tmp = data_tmp.filter(d => d.time >= start);

	let extents = d3.extent(data_tmp, (e) => e.temp)
		.concat(d3.extent(data_tmp, (e) => e.target))
	extents = d3.extent(extents);
	extents = [ Math.floor(extents[0] * 2 - 0.001) / 2,
		    Math.ceil(extents[1] * 2 + 0.001) / 2 ];
	if (extents[1] - extents[0] < min_temp_height) {
		let middle = (extents[1] + extents[0]) / 2.0;
		extents[0] = middle - (min_temp_height / 2);
		extents[1] = middle + (min_temp_height / 2);
	}

	x_scale.rangeRound([0, width])
	if (data_tmp.length)
		x_scale.domain([start * 1000, data_tmp[data_tmp.length - 1].time * 1000])
	y_scale.range([height, 0])
		.domain(extents)
	ytrans_scale.range([height + 1, -1])
		.domain([0, 1])
	yon_scale.range([height, 0])
		.domain([0, 1])

	/* update axes */
	let baxis = d3.axisBottom(x_scale)
			.ticks(Math.ceil(width / 100))
			//.ticks(d3.timeMinute.every(30))
	let laxis = d3.axisLeft(y_scale)
			.ticks(6, ",f")
	x_axis.attr("transform", `translate(${margins.left},${height + margins.top})`)
		.call(baxis)
		//.call(baxis.tickFormat(d3.timeFormat("%H:%M")))
	y_axis.call(laxis)

	/* update grids */
	x_grid.call(baxis.tickSize(height)
			.tickFormat(""))
	y_grid.call(laxis.tickSize(-width)
			.tickFormat(""))

	/* update line chart */
	line_chart.datum(data_tmp)
		.attr("d", line)
	target_chart.datum(data_tmp)
		.attr("d", tline)

	/* update cooler and heater charts */
	cooler_chart.datum(data_tmp)
		.attr("d", cline)
	heater_chart.datum(data_tmp)
		.attr("d", hline)
	cooler_area.datum(data_tmp)
		.attr("d", carea)
	heater_area.datum(data_tmp)
		.attr("d", harea)
	chtrans.datum(data_tmp)
		.attr("d", chtransarea)
	con_chart.datum(data_tmp)
		.attr("d", con)
	hon_chart.datum(data_tmp)
		.attr("d", hon)
}

const RegulatorGraph = ({id, timescale}) => {
	const classes = useStyles();
	const [height, setHeight] = useState(0);
	const [width, setWidth] = useState(0);
	const ref = useRef(null);
	const data = useSelector(({app}) => app.regulators[id]?.data);

	const svg = ReactFauxDOM.createElement('svg');

	useEffect(() => {
		setHeight(ref.current.clientHeight);
		setWidth(ref.current.clientWidth);
	}, []);

	d3.select(svg)
	    .attr('viewBox', `0 0 ${width} ${height}`)

	render({id, data, svg: d3.select(svg), width, height, timescale, classes});

	return (
		<div ref={ref} className={classes.root}>
			{svg.toReact()}
		</div>
	);
}

export default RegulatorGraph;
